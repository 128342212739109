<template>
  <div class="about">

    <div class="content-header row">
      <div class="content-header-left col-md-9 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <div class="col-12">

            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Dashboard</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>


      <div class="card" >
        <div class="card-content">
          <div class="card-body">

            <div class="container">
              <div class="row">
                <div class="col-md-4 text-center">
                  <a href="/app/getQuote"  >
                    <div style="border: 1px solid #ccc" class="p-4">
                      <div>
                        <img src="../app-assets/images/add-policy.png"  />
                      </div>
                      <div>
                        Add Policy
                      </div>
                    </div>

                  </a>
                </div>
                <div class="col-md-4 text-center">
                  <a href="#">
                    <div style="border: 1px solid #ccc" class="p-4">
                      <div>
                        <img src="../app-assets/images/file-claim.png"  />
                      </div>
                      <div>
                        File Claim
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-4 text-center">
                  <a href="#">
                    <div style="border: 1px solid #ccc" class="p-4">
                      <div>
                        <img src="../app-assets/images/support.png"  />
                      </div>
                      <div>
                        Support
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row" v-if="vehicles.length < 1">
        <div class="col-3">

        </div>
        <div class="col-6">
          <div class="card" >
            <div class="card-content">
              <div class="card-body p-5" style="background-color: #20303F; border-radius: 5px;">
                <div class="text-center">
                  <h5 style="color:#fff;">You have no active policy!</h5>
                </div>
                <div class="text-center mt-4">
                  <a style="color:#B3D020;border:1px solid #B3D020;"  href="/app/getQuote" class="btn">Get Quote </a>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-3">

        </div>
      </div>
    <div class="row" v-else>
      <div class="col-4" v-for="vehicle in vehicles">
        <div class="card" >
          <div class="card-content">
            <div class="card-body p-5" style="background-color: #20303F; border-radius: 5px;">
              <div class="text-center">
                <div class="row">
                  <div class="col-md-6">
                    <apexchart type="radialBar" height="200" :options="chartOptions" :series="series"></apexchart>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <span style="color:#fff;" class="float-right">{{ vehicle.n_plate }}</span>
                    </div>
                    <div style="margin-top: 60px;">
                      <h4> <span style="color:#D7FF1D;"> {{ vehicle.packages.switch.used_mileage | currency('', 0) }} <br/> </span> <span style="color:#fff;"> KM Covered </span></h4>
                    </div>

                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

     <div class="card" >
      <div class="card-content">
        <div class="card-body">
          <div class="container">
            <div class="row mt-2 mb-3">
              <div class="col-12">
                <h5>Driving Tips</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 text-center">
                <iframe style="width: 100%" height="200" src="https://www.youtube.com/embed/vEn3C4rsYs0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 text-center">
                <iframe style="width: 100%" height="200" src="https://www.youtube.com/embed/fVl88Q5DJ2w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 text-center">
                <iframe style="width: 100%" height="200" src="https://www.youtube.com/embed/VFxSVl3wLVQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>

import { mapActions } from "vuex";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'home',
  data () {
    return {
      vehicles:[],
      series: [0],
      chartOptions: {
        chart: {
          height: 200,
          type: 'radialBar',
          toolbar: {
            show: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px'
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                color: '#111',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#D7FF1D'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['KM Left'],
      },
    };
  },
  mounted(){
    this.getKilometerWalletData();
  },
  components: {
    apexchart: VueApexCharts,
  },

  methods:{
    ...mapActions(["getKilometerWallet"]),

    getKilometerWalletData(){
      let self = this;

      this.isLoading = true;
      this.getKilometerWallet({id: this.$route.params.policyId}).then((walletData) => {
        self.isLoading = false;
        this.vehicles = walletData.vehicles;

        this.vehicles.forEach(function (vehicle){
          let mileageLeft = vehicle.packages.switch.mileage - vehicle.packages.switch.used_mileage
          mileageLeft = mileageLeft > 0 ? mileageLeft : 0

          self.series.push(mileageLeft)
        });

        console.log(self.series)

      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })
    },

  }
}
</script>
<style>
  .apexcharts-toolbar{
    display: none !important;
  }
</style>
